import React from 'react'
import availableLangs from '@localization/availableLangs'
import MainPageContainer from '@containers/MainPageContainer'
import SeoContainer from '@containers/SeoContainer'

const IndexPage = () => {
  return (
    <>
      <SeoContainer pageLang={availableLangs.de} pagePath='' />
      <MainPageContainer />
    </>
  )
}

export default IndexPage
